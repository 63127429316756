import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

function ForApplicants() {
  return (
    <Layout>
      <SEO
        keywords={[
          `Абитуриентам`,
          `Профф`,
          `Пыть-Ях`,
          `Профессиональное образование`,
        ]}
        title='Абитуриентам'
      />

      <section className='flex flex-col items-center '>
        <h1 className='text-3xl text-center'>Абитуриентам</h1>
      </section>
    </Layout>
  );
}

export default ForApplicants;
